<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;" :fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id" :defaultValue="corp_id"
						:options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
						<a-tree-select
						    v-model="corp_id"
							 @change="corpChange"
						   style="width: 300px;"
						    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						    :tree-data="corpList"
						    placeholder="请选择客户"
							:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
						  >
						  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="SN号">
					<a-input size="default" v-model="snumber" placeholder="输入SN号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button v-if="btnIds.includes(117)" type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="status" slot-scope="text, record">
					<a-tag color="blue" v-if="record.status == 0">
						正常
					</a-tag>
					<a-tag color="orange" v-if="record.status == 1">
						已撤销
					</a-tag>
					<a-tag color="red" v-if="record.status == 2">
						已过期
					</a-tag>
					<a-tag color="purple" v-if="record.status == 3">
						已完成
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record"> 
					<span class="btn-span" @click="edit(record)" v-if="record.status == 0 && btnIds.includes(118)">
						编辑
					</span>
					<a-popconfirm placement="top" v-if="record.status == 0 && btnIds.includes(120)" ok-text="确定" cancel-text="取消" @confirm="remove1(record.task_id)">
						<template slot="title">
							<p>是否确定取消该任务？</p>
						</template>
						<span class="btn-span btn-span-delete" >
							取消
						</span>
					</a-popconfirm>
					<a-popconfirm v-if="btnIds.includes(119)" placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.task_id)">
						<template slot="title">
							<p>是否确定删除该任务？</p>
						</template>
						<span class="btn-span btn-span-delete" >
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.task_id ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="chcorpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id" label="选择网点" prop="node_id">
					<a-select style="width: 100%" show-search :filter-option="filterOption" @change="chnodeChange" v-model="form.node_id" placeholder="请选择网点" >
					   <a-select-option :value="item.node_id" v-for="(item, index) in nodeList" :key="index">
					       {{item.name}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.node_id" label="选择设备" prop="device_id">
					<a-select style="width: 100%" show-search :filter-option="filterOption" v-model="form.device_id" placeholder="请选择设备" >
					   <a-select-option :value="item.id" v-for="(item, index) in deviceList" :key="index">
					       {{item.name}}-{{item.number}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id" label="执行人A" prop="user_fid">
					<a-select style="width: 100%" show-search :filter-option="filterOption" v-model="form.user_fid"  placeholder="请选择执行人A">
					   <a-select-option :value="item.user_id" v-for="(item, index) in userList" :key="index">
					       {{item.user_name}}- {{item.real_name}}
					   </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="form.corp_id" label="执行人B" prop="user_sid">
					<a-select style="width: 100%" show-search :filter-option="filterOption" v-model="form.user_sid" placeholder="请选择执行人B">
					    <a-select-option :value="item.user_id" v-for="(item, index) in userList" :key="index">
					        {{item.user_name}}- {{item.real_name}}
					    </a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="开始时间" prop="start_time">
					<a-date-picker style="width: 100%;" v-model="form.start_time" format="YYYY-MM-DD HH:mm:ss" show-time />
				</a-form-model-item>
				<a-form-model-item label="结束时间" prop="end_time">
					<a-date-picker style="width: 100%;" v-model="form.end_time" format="YYYY-MM-DD HH:mm:ss" show-time />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [{
			title: '设备名称',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'SN号',
			key: 'snumber',
			dataIndex: 'snumber',
		},
		{
			title: '执行人A',
			key: 'user_fname',
			dataIndex: 'user_fname',
		},
		{
			title: '执行人B',
			key: 'user_sname',
			dataIndex: 'user_sname',
		},
		{
			title: '任务开始时间',
			key: 'start_time',
			dataIndex: 'start_time',
		},
		{
			title: '任务结束时间',
			key: 'end_time',
			dataIndex: 'end_time',
		},
		{
			title: '创建时间',
			key: 'add_time',
			dataIndex: 'add_time',
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			scopedSlots: {
				customRender: 'status'
			},
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					task_id: 0,
					corp_id: undefined,
					node_id: undefined,
					device_id: undefined,
					user_fid: undefined,
					user_sid: undefined,
					start_time: '',
					end_time: '',
				},
				rules: {
					corp_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
					node_id: [{
						required: true,
						message: '请选择网点',
						trigger: 'change'
					}],
					device_id: [{
						required: true,
						message: '请选择设备',
						trigger: 'change'
					}],
					user_fid: [{
						required: true,
						message: '请选择执行人A',
						trigger: 'change'
					}],
					user_sid: [{
						required: true,
						message: '请选择执行人B',
						trigger: 'change'
					}],
					start_time: [{
						required: true,
						message: '请选择开始时间',
						trigger: 'change'
					}],
					end_time: [{
						required: true,
						message: '请选择结束时间',
						trigger: 'change'
					}],
				},
				
				snumber: '',
				corp_id: null,
				tableData: [],
				tableTotal: 0,
				tablePage: 1, 
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random(),
				nodeList: [],
				deviceList: [],
				userList: [],
				
				btnIds: localStorage.getItem('btn_ids') ? JSON.parse(localStorage.getItem('btn_ids')) : []
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
			})
		},
		methods: {
			filterOption (input, option) {
				 return (
				        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
				      );
			},
			onChange (data) {
				this.form.start_date = moment(data[0]).format('YYYY-MM-DD')
				this.form.end_date = moment(data[1]).format('YYYY-MM-DD')
			},
			corpChange (data) {
				this.corp_id = data
			},
			chcorpChange (data) {
				this.form.corp_id = data
				this.getFormList()
			},
			chnodeChange (data) {
				this.form.node_id = data
				this.getNodeFormList()
			},
			getFormList (corpid) {
				http({
					method: 'post',
					url: '/api.user.ctreelist/',
					data: {
						corp_id: corpid ? corpid : this.form.corp_id
					}
				}).then((res) => {
					this.userList = res.data
				})
				http({
					method: 'post',
					url: '/api.corp.node.treelist/',
					data: {
						corp_id: corpid ? corpid : this.form.corp_id
					}
				}).then((res) => {
					this.nodeList = res.data
				})
			},
			getNodeFormList (nodeid) {
				http({
					method: 'post',
					url: '/api.device.node.treelist/',
					data: {
						node_id: nodeid ? nodeid : this.form.node_id,						
						corp_id: this.form.corp_id
					}
				}).then((res) => {
					this.deviceList = res.data
				})
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.snumber = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.task.list/',
					data: {
						snumber: this.snumber,
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.deposit.task.del/',
					data: {
						task_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除任务成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			remove1(id) {
				http({
					method: 'post',
					url: '/api.deposit.task.cancel/',
					data: {
						task_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('取消任务成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.form = {
					...data,
					corp_id: data.corp_id,
				}
				let corpid = this.form.corp_id[this.form.corp_id.length - 1]
				this.getFormList(corpid)
				this.getNodeFormList(this.form.node_id)
				this.modelVisible = true;
				this.formKey = Math.random()
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form,
							start_time: moment(this.form.start_time).format('YYYY-MM-DD HH:mm:ss'),
							end_time: moment(this.form.end_time).format('YYYY-MM-DD HH:mm:ss'),
						}
						delete newData.corp_name
						delete newData.status
						delete newData.user_fname
						delete newData.user_sname
						delete newData.snumber
						delete newData.add_time
						delete newData.name
						if (typeof newData.corp_id == 'object') {
							newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
						}
						http({
							method: 'post',
							url: '/api.deposit.task.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.task_id) {
									this.$message.success('编辑任务信息成功');
								} else {
									this.$message.success('添加任务成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					task_id: 0,
					corp_id: undefined,
					node_id: undefined,
					device_id: undefined,
					user_fid: undefined,
					user_sid: undefined,
					start_time: '',
					end_time: '',
				}
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
