<template>
	<div class="home-page">
		<div class="bbsds">
			<div class="home-title">
				数据概况
			</div>
			<div class="city-num-list">
				<div class="item">
					<img class="right" src="../../../assets/m1.png"></img>
					<div class="left">
						<h5>今日纸币存款总额</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal1' :endVal='endVal1' :duration='3000' />
						</div>
					</div>
				</div>
				<div class="item">
					<img class="right" src="../../../assets/m2.png"></img>
					<div class="left">
						<h5>今日纸币存款次数</h5>
						<div>
							<countTo :startVal='startVal2' :endVal='endVal2' :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m3.png"></img>
					<div class="left">
						<h5>今日信封存款总额</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal3' :endVal='endVal3' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m4.png"></img>
					<div class="left">
						<h5>今日信封存款次数</h5>
						<div>
							<countTo :startVal='startVal4' :endVal='endVal4' :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m5.png"></img>
					<div class="left">
						<h5>累计存款次数</h5>
						<div>
							<countTo :startVal='startVal5' :endVal='endVal5' :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m6.png"></img>
					<div class="left">
						<h5>本月累计存款总额</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal6' :endVal='endVal6' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m7.png"></img>
					<div class="left">
						<h5>累计纸币存款总额 </h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal7' :endVal='endVal7' :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m8.png"></img>
					<div class="left">
						<h5>累计信封存款总额</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal8' :endVal='endVal8' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m9.png"></img>
					<div class="left">
						<h5>累计存款总额</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal9' :endVal='endVal9' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="right" src="../../../assets/m10.png"></img>
					<div class="left">
						<h5>设备终端总数</h5>
						<div>
							<countTo :startVal='startVal10' :endVal='endVal10' :duration='3000' />
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="echbox">
			<div class="ecbix1">
				<div class="home-title">
					实时数据
					<span>更新时间：{{time}}</span>
				</div>
				<div class="ecb1-tabs">
					<div :class="{'active': lineType == 'amount_day'}" @click="setLineType('amount_day')">当日</div>
					<div :class="{'active': lineType == 'amount_month'}" @click="setLineType('amount_month')">本月</div>
					<div :class="{'active': lineType == 'amount_year'}" @click="setLineType('amount_year')">本年</div>
				</div>
				<div class="echtes" id="myChart"></div>
			</div>
			<div class="ecbix2">
				<div class="home-title teteds2">
					排行榜
				</div>

				<div class="table-list">
					<div class="table-title">
						<p style="width: 12%;">排名</p>
						<p>网点名称</p>
						<p>设备名称</p>
						<p>存款金额(元)</p>
					</div>
					<div>
						<div class="table-item" v-for="(item, index) in homeInfo.device_list" :key="index">
							<p style="width: 12%;">{{index + 1}}</p>
							<p>{{item.node_name}}</p>
							<p>{{item.name}}</p>
							<p>{{item.amount}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="ecbix1">
				<div class="home-title">
					最近一月存款日结情况
				</div>
				<div>
					<div class="echtes" id="myChart2"></div>

				</div>
			</div>
			<div class="ecbix2">
				<div class="home-title home-titlebxs">
					存款分布  <a-select size="large"
				    show-search
				    placeholder="查看占比"
				    option-filter-prop="children"
				    style="width: 330px;margin-left: 10px;"
				    :filter-option="filterOption"
					v-model="filterValue"
				  >
				    <a-select-option :value="item.name + '_' + index" v-for="(item, index) in filterList">
				      {{item.name}}： <span style="font-size: 12px;margin-right: -4px;">￥</span> {{item.value}} {{ item.value ? ('(' + toPercentage(item.value, filtertotal) + '%)') : ''}}
				    </a-select-option>
				  </a-select>
				</div>
				
				<div class="echtes" id="myChart3"></div>
				<div class="topte">存款分布</div>
				
				
			</div>
		</div>
	</div>
</template>

<script>
	import http from  '@/http'
	import countTo from 'vue-count-to';
	import moment from 'moment'
	
	function Percentage(num, total) {
	    if (num == 0 || total == 0){
	        return 0;
	    }
	    return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
	}
	
	export default {
		data() {
			return {
				homeInfo: {
					count: {},
					line_amount_count: [],
					device_count_province: [],
					node_amount_day: []
				},
				startVal1: 0,
				endVal1: 0,
				
				startVal2: 0,
				endVal2: 0,
				
				startVal3: 0,
				endVal3: 0,
				
				startVal4: 0,
				endVal4: 0,
				
				startVal5: 0,
				endVal5: 0,
				
				startVal6: 0,
				endVal6: 0,
				
				startVal7: 0,
				endVal7: 0,
				
				startVal8: 0,
				endVal8: 0,
				
				startVal9: 0,
				endVal9: 0,
				
				startVal10: 0,
				endVal10: 0,
				
				lineType: 'amount_day',
				
				time: moment().format('YYYY-MM-DD HH:mm:ss'),
				
				filterList: [],
				filtertotal: 0,
				filterValue: ''
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.platform.data/',
				data: {}
			}).then((res) => {
				this.homeInfo = res.data
				this.endVal1 = parseFloat(res.data.count.camount_today)
				this.endVal2 = parseFloat(res.data.count.ccount_today)
				this.endVal3 = parseFloat(res.data.count.eamount_today)
				this.endVal4 = parseFloat(res.data.count.ecount_today)
				this.endVal5 = parseFloat(res.data.count.count)
				this.endVal6 = parseFloat(res.data.count.amount_month)
				this.endVal7 = parseFloat(res.data.count.camount)
				this.endVal8 = parseFloat(res.data.count.eamount)
				this.endVal9 = parseFloat(res.data.count.amount)
				this.endVal10 = parseFloat(res.data.count.device_count)
				
				this.drawLine();
				this.drawLine3("myChart3");
				this.drawLine2("myChart2");
				
				this.time = moment().format('YYYY-MM-DD HH:mm:ss')
			})
		},
		computed: {
			getLineData () {
				return this.homeInfo[this.lineType]
			}
		},
		components: {
			countTo
		},
		methods: {
			toPercentage (num, total) {
				if (num == 0 || total == 0){
				    return 0;
				}
				return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
			},
			filterOption(input, option) {
			      return (
			        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
			      );
			    },
			setLineType (type) {
				this.lineType = type
				this.drawLine();
			},
			drawLine2() {
				let myChart = this.$echarts.init(document.getElementById("myChart2"));

				const labelOption = {
					show: true,
					position: "insideBottom",
					distance: 19,
					align: 'left',
					verticalAlign: 'middle',
					rotate: 90,
					formatter: '{c}  {name|{a}}',
					fontSize: 16,
					rich: {
						name: {}
					}
				};
				let data = []
				let name = []
				this.homeInfo.line_account.forEach((v) => {
					name.push(v.name)
					data.push(v.amount)
				})
				var option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						right: '5%',
						top: '10%',
						left: '5%',
						bottom: 0,
						containLabel: true,
					},
					xAxis: [{
						type: 'category',
						axisTick: {
							show: false
						},
						data: name,
						splitLine: {
							lineStyle: {
								color: "rgba(131,101,101,0.2)",
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
					}],
					yAxis: [{
						type: 'value',
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
						},
						axisLabel: {
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						splitLine: {
							lineStyle: {
								color: "rgba(131,101,101,0.2)",
							},
						},
					}],
					series: [{
							name: '日结',
							type: 'bar',
							barGap: 1,
							emphasis: {
								focus: 'series'
							},
							data: data,
							color: '#4E6BEF',
							barWidth: 12,
						},			

					]
				};
				myChart.setOption(option);
			},
			drawLine() {
				let myChart = this.$echarts.init(document.getElementById("myChart"));
				// 绘制图表
				let self = this;
				let xAxis = [];
				let bar = []
				let line = []
				let dataArr = []
				dataArr.forEach((v) => {
					xAxis.push(v.name)
					bar.push(v.data1)
					line.push(v.data2)
				})
				
				let names = []
				let d1 = []
				let d2 = []
				let d3 = []
				this.getLineData.forEach((v) => {
					names.push(v.name)
					d1.push(v.amount)
					d2.push(v.zamount)
					d3.push(v.eamount)
				})
				var option = {
					backgroundColor: "#fff",
					title: [],
					tooltip: {
						show: true,
						trigger: 'axis',
						
					},
					grid: {
						top: "24%",
						right: "2%",
						left: "2%",
						bottom: "10%",
						containLabel: true,
					},
					legend: {
						icon: 'rect',
						bottom: 0,
						itemWidth: 10,
						itemHeight: 10,
						itemGap: 20,
						textStyle: {
							color: "#556677",
						},
					},
					xAxis: [{
						type: "category",
						color: "#59588D",
						data: names,
						axisLabel: {
							margin: 20,
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
						axisTick: {
							show: false,
						},
					}, ],
					yAxis: [{
						name: '存款金额',
						axisLabel: {
							formatter: "{value}",
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							lineStyle: {
								color: "rgba(131,101,101,0.2)",
								type: "dashed",
							},
						},
					}, {
						name: '存款笔数',
						axisLabel: {
							formatter: "{value}",
							color: "#999",
							textStyle: {
								fontSize: 14,
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(107,107,107,0.37)",
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							lineStyle: {
								color: "rgba(131,101,101,0.2)",
								type: "dashed",
							},
						},
					}, ],
					series: [{
							name: '存款总额',
							type: 'line',
							data: d1,
							smooth: true, // 平滑曲线
							showSymbol: false,
							itemStyle: {
								color: '#4E6BEF'
							},
							lineStyle: {
								width: 3,
								color: '#4E6BEF'
							},
							areaStyle: {
								normal: {
									color: new self.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: 'rgba(78, 107, 239, 0.5)'
										},
										{
											offset: 1,
											color: 'rgba(78, 107, 239, 0.1)'
										}
									], false),
								}
							},
						},
						{
							name: '现金存款',
							type: 'line',
							data: d2,
							smooth: true,
							showSymbol: false,
							yAxisIndex: 1,
							itemStyle: {
								color: '#4ACC8C'
							},
							lineStyle: {
								width: 3,
								color: '#4ACC8C'
							},
							areaStyle: {
								normal: {
									color: new self.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: 'rgba(74, 204, 140, 0.5)'
										},
										{
											offset: 1,
											color: 'rgba(74, 204, 140, 0.1)'
										}
									], false),
								}
							},
						},
						{
							name: '信封存款',
							type: 'line',
							data: d3,
							smooth: true,
							showSymbol: false,
							yAxisIndex: 1,
							itemStyle: {
								color: '#FF7272'
							},
							lineStyle: {
								width: 3,
								color: '#FF7272'
							},
							areaStyle: {
								normal: {
									color: new self.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: 'rgba(255, 114, 114, 0.5)'
										},
										{
											offset: 1,
											color: 'rgba(255, 114, 114, 0.1)'
										}
									], false),
								}
							},
						},
					],
				};
				myChart.setOption(option);
			},
			drawLine3(id, data) {
				let myChart = this.$echarts.init(document.getElementById(id));
				var data1 = [				    
				];
				let total = 0
				this.homeInfo.type_ratio.forEach((v) => {
					data1.push({
						name: v.type_name,
						value: v.amount,
					})
					total += v.amount
				})
				this.filtertotal = total
				this.filterList = [...data1].sort((a, b) => {
    return b.value - a.value
});
this.filterValue = this.filterList[0].name + '_0'

				console.log(data1, 'data1data1')
				var colorList = ['#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac', '#0090ff', '#06d3c4', '#ffbc32', '#2ccc44', '#ff3976', '#6173d6', '#914ce5', '#42b1cc',
					'#ff55ac'
				];
				
				
				var option = {
				    tooltip: {
				        show: 'none'
				    },
				    legend: {
				        bottom: 0,
						itemGap: 20,
						itemWidth: 10,
						itemHeight: 10,
				        textStyle: {
				            fontSize: 14,
				            color: 'black',
				        },
						tooltip: {
						    show: 'none'
						},
				        formatter: function (name) {
				            const item = data1.filter((item) => item.name === name)[0];
				            return `${name}：${Percentage(item.value, total)}%`;
				        },
						show: false
				    },
				    series: [
				        {
				            type: 'pie',
				            center: ['50%', '50%'],
				            radius: ['60%', '80%'],
				            avoidLabelOverlap: false,
				            label: {
				                show: false,
				                position: 'center',
				            },
				            itemStyle: {
				                normal: {
				                    color: function (params) {
				                        return colorList[params.dataIndex];
				                    },
				                },
				            },				            
				            labelLine: {
				                show: false,
				            },
				            data: data1,
				        },
				    ],
				};

				myChart.setOption(option);
			},
		},
	};
</script>
<style lang="less">
	.topte{
		    position: absolute;
		    top: 51%;
		    left: 0px;
		    width: 100%;
		    text-align: center;
		    font-size: 20px;
	}
	
	.home-titlebxs {
		justify-content: flex-start !important;
	}
	.teteds2 {
		height: 58px;
		line-height: 58px;
	}

	.table-list {
		.table-title {
			display: flex;
			height: 45px;
			background: #F3F7FF;
			border-radius: 5px;

			p {
				font-size: 16px;
				font-weight: 400;
				color: #262B4F;
				line-height: 45px;
				width: 30%;
				margin-bottom: 0px;
				text-align: center;
			}
		}

		.table-item {
			display: flex;
			height: 45px;
			border-radius: 5px;

			&:nth-child(2n) {
				background-color: #F9FBFF;
			}

			p {
				font-size: 16px;
				font-weight: 400;
				color: #262B4F;
				line-height: 45px;
				width: 30%;
				margin-bottom: 0px;
				text-align: center;
			}
		}
	}

	.bbsds {
		height: 320px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 23px;
	}

	.gzlb {
		padding-top: 130px;

		h5 {
			display: flex;

			span {
				width: 10px;
				height: 10px;
				margin-right: 9px;
				background: #FF635F;
			}

			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 14px;
			margin-bottom: 20px;
		}

		ul {
			padding-left: 19px;

			li {
				list-style: none;
				margin-bottom: 20px;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 14px;
			}
		}
	}

	.ecb1-tabs {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		top: 80px;
		z-index: 111;
		right: 26px;
		border: 1px solid #CCCCCC;
		border-right: none;
		border-radius: 8px;
		width: 210px;
		overflow: hidden;

		div {
			cursor: pointer;
			width: 70px;
			border-right: 1px solid #E5E5E5;
			height: 36px;
			background: #FFFFFF;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #262B4F;
			display: flex;
			align-items: center;
			justify-content: center;

			&.active {
				border-right: 1px solid #4E6BEF;
				background-color: #4E6BEF;
				color: #fff;
			}
		}
	}

	.city-num-list {
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 20%;
			background-color: #fff;
			height: 100px;
			background: #FFFFFF;
			display: flex;
			margin-bottom: 14px;

			&:nth-child(4n) {
				margin-right: 0px;
			}

			&:nth-child(5) {
				margin-bottom: 0px;
			}

			&:nth-child(6) {
				margin-bottom: 0px;
			}

			&:nth-child(7) {
				margin-bottom: 0px;
			}

			&:nth-child(8) {
				margin-bottom: 0px;
			}

			.left {
				display: flex;
				height: 100px;
				box-sizing: border-box;
				flex-direction: column;
				padding-top: 30px;

				h5 {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #757892;
					margin-bottom: 14px;
					line-height: 18px;
				}

				&>div {
					font-size: 20px;
					font-family: Alibaba PuHuiTi;
					font-weight: 400;
					color: #262B4F;
					font-weight: 700;
					line-height: 24px;

					&>span:nth-child(2) {
						margin-left: -8px;
					}
				}
			}

			.right {
				width: 70px;
				height: 70px;
				border-radius: 20px;
				margin-right: 23px;
				margin-left: 28px;
				margin-top: 25px;
			}
		}
	}

	.home-page {
		.home-title {
			font-size: 18px;
			font-weight: bold;
			color: #333333;
			line-height: 60px;
			justify-content: space-between;
			display: flex;
			align-items: center;

			span {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #757892;
				line-height: 36px;
			}
		}

		.header1-box {
			background-color: #fff;
			height: 130px;
			display: flex;
			justify-content: space-between;
			padding: 0 30px;
			box-sizing: border-box;
			align-items: center;
			border-radius: 5px;

			div {
				width: 22%;
				height: 100px;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				justify-content: center;
				padding: 0 20px;
				border-radius: 5px;

				&:nth-child(1) {
					background: #73ACFF;
				}

				&:nth-child(2) {
					background: #FD866A;
				}

				&:nth-child(3) {
					background: #9E87FF;
				}

				&:nth-child(4) {
					background: #FFA54D;
				}

				p {
					font-size: 14px;
					line-height: 18px;
					margin: 0px;
					font-weight: 600;
					color: #fff;
				}

				h2 {
					font-size: 28px;
					color: #fff;
					margin: 0px;
					margin-top: 2px;
				}
			}
		}

		.header2-box {
			margin-top: 24px;
			background-color: #fff;
			padding-bottom: 20px;
			border-radius: 5px;

			.infos1 {
				display: flex;
				height: 120px;
				padding-top: 20px;

				div {
					width: 20%;
					height: 100px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					padding: 0 20px;

					p {
						font-size: 14px;
						line-height: 18px;
						margin: 0px;
						font-weight: 700;
					}

					h2 {
						font-size: 28px;
						font-weight: 700;
						color: #1890ff;
						margin: 0px;
						margin-top: 2px;
					}
				}
			}

			.infos2 {
				display: flex;
				height: 100px;
				box-shadow: 0px 4px 11px 0px #b4b3b454;
				margin: 0 30px;
				border-radius: 10px;

				div {
					width: 20%;
					height: 100px;
					display: flex;
					align-items: center;
					flex-direction: column-reverse;
					justify-content: center;
					padding: 0 20px;
					border-right: 1px solid #b4b3b454;

					p {
						font-size: 14px;
						line-height: 18px;
						margin: 0px;
						font-weight: 700;
					}

					h2 {
						font-size: 24px;
						font-weight: 700;
						margin: 0px;
						margin-top: 2px;
						color: rgba(0, 0, 0, 0.65);
					}
				}
			}
		}
	}

	.echbox {
		display: flex;
		flex-wrap: wrap;
		margin-top: 24px;
		justify-content: space-between;
	}

	.ecbix1 {
		width: 60%;
		margin-bottom: 20px;
		overflow: hidden;
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		padding: 0 23px;
		height: 561px;
	}

	.echtes {
		height: 470px;
		border-radius: 5px;
		background-color: #fff;
	}

	.ecbix2 {
		height: 561px;
		position: relative;
		width: calc(40% - 24px);
		margin-left: 24px;
		overflow: hidden;
		background-color: #fff;
		border-radius: 10px;
		padding: 0 23px;
	}
</style>
